@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700');

@font-face {
  font-family: Proximanova-Extrabold;
  src: url(./assets/fonts/proximanova-extrabold.otf);
}

* {
   box-sizing: border-box;
}

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 100vh;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

/* Ziggeo settings */

.ba-player-space-theme-message-container {
  display: flex;
  height: 100%;
  z-index: 10;
  align-items: center;
  color: white;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.ba-videorecorder-circle-button {
  display: none;
}

.ba-recorder-blue-color .ba-videorecorder-chooser-button-0, .ba-recorder-blue-color .ba-videorecorder-chooser-button-2, .ba-recorder-blue-color .ba-videorecorder-imagegallery-leftbutton, .ba-recorder-blue-color .ba-videorecorder-imagegallery-rightbutton {
  background-color: #1247F6 !important;
}

.ba-recorder-blue-color .ba-videorecorder-chooser-button-0, .ba-recorder-blue-color .ba-videorecorder-chooser-button-2, .ba-recorder-blue-color .ba-videorecorder-imagegallery-image:hover {
  border-color: #1247F6 !important;
}

.ba-recorder-blue-color .ba-videorecorder-button-circle-selected, .ba-recorder-blue-color .ba-videorecorder-button-inner:hover, .ba-recorder-blue-color .ba-videorecorder-chooser-button-1, .ba-recorder-blue-color .ba-videorecorder-chooser-button-3, .ba-recorder-blue-color .ba-videorecorder-chooser-icon-container {
  color: #1247F6 !important;
}

.ba-videorecorder-container {
  max-width: 100%;
}

.ba-videorecorder-container > img {
  max-height: 100%;
  max-width: 100%;
}

.ba-recorder-overlay {
  position: static!important;
}

.ba-videoplayer-video {
  max-height: 500px;
  margin: auto;
  max-width: 100%;
  width: auto !important;
}

.ba-videoplayer-container {
  max-height: 500px;
  min-width: 200px;
  min-height: 200px;
  max-width: 100%;
}

.ba-player-fullscreen-view video, .ba-player-fullscreen-view>div {
  width: 100%;
  height: 100%;
}
